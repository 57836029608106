<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("ECHO_INVOICES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            v-permission="['stock-actions-show']"
            to="invoice-list"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-document"></i>
            {{ $t("INVOICES") }}
          </router-link>
          <v-btn
            color="btn-light-success"
            class="btn btn-light-success font-weight-bolder mr-2"
            @click="sendEchoInvoicesToAjur()"
          >
            <i class="far fa-edit"></i>
            {{ $t("ECHO_INVOICE_TO_AJUR") }}
          </v-btn>
        </div>
      </template>
      <EchoInvoiceListTable
        :isPageLoading.sync="isPageLoading"
        :firstLoader.sync="firstLoader"
        :isPreviewMode="false"
      ></EchoInvoiceListTable>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <v-overlay :value="!firstLoader && isLoadingSharedStore">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General
import { SEND_ECHO_INVOICES_TO_AJUR } from "@/modules/stock/store/stock.module";
import EchoInvoiceListTable from "@/modules/stock/components/actions/stockList/invoice/EchoInvoiceListTable.vue";
import { mapGetters } from "vuex";
//Shared Modules

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import _ from "lodash";
export default {
  name: "EchoInvoiceList",
  mixins: [permissionMixin],
  data() {
    return {
      firstLoader: true,
      isPageLoading: true
    };
  },

  components: {
    EchoInvoiceListTable
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ])
  },
  methods: {
    sendEchoInvoicesToAjur() {
      let vm = this;
      let message = vm.$t("PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(SEND_ECHO_INVOICES_TO_AJUR)
            .then(data => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
            })
            .catch(response => {
              console.log(response);
              if (response.status === 404) {
                vm.$notify({
                  group: "notify",
                  type: "error",
                  text: "Not Found"
                });
              }
            });
        })
        .catch(function() {});
    }
  }
};
</script>
